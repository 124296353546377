const score = {
   skill: {
      id: 'skill',
      points: 1
   },
   strength: {
      id: 'strength',
      points: 1
   },
   care: {
      id: 'care',
      points: 1
   },
   character: {
      id: 'character',
      points: 1
   }
};

export const globalPassword = 'ganimede';

const pages = [
   {
      id: 'intro',
      title: 'Benvenuti',
      text: `Benvenuti! State per iniziare una splendida avventura in cui dovrete fare molte scelte.
      <br>Ogni volta che farete una scelta potrete guadagnare o meno dei punti e cambiare l'andamento della storia
      quindi fate molta attenzione alle decisioni che prenderete!
      <br>Quando siete pronti premete su 'Continua'`,
      choices: [
         {
            label: 'Continua',
            goTo: 'uscita'
         }
      ]
   },
   {
      id: 'uscita',
      title: "L'uscita di squadriglia",
      text: `Mancano poche ore all'inizio della tanto attesa uscita di squadriglia. 
      Lo zaino è quasi pronto ma c'è posto ancora per una cosa. Decidete di prendere:`,
      choices: [
         {
            label: 'La borraccia',
            goTo: 'fermata-pullman'
         },
         {
            label: 'Il poncho',
            goTo: 'fermata-pullman'
         },
         {
            label: 'La bussola',
            goTo: 'fermata-pullman'
         }
      ]
   },
   {
      id: 'fermata-pullman',
      title: 'Alla fermata del pullman',
      text: `Vi ritrovate tutti alla fermata del pullman pronti per partire ma manca il solito ritardatario della squadriglia.<br>
      Dovete decidere se aspettarlo e prendere il pullman successivo oppure partire senza lui (in qualche modo vi raggiungerà).
      `,
      choices: [
         {
            label: 'Partiamo senza aspettare',
            goTo: 'biglietti'
         },
         {
            label: 'Prendiamo il pullman dopo',
            goTo: 'biglietti',
            score: [score.care]
         }
      ]
   },
   {
      id: 'biglietti',
      title: 'I biglietti del pullman',
      text: `L'incaricato alla cassa di squadriglia ha comprato i biglietti 
      ma non sapete se timbrarli o usarli in un'altra occasione.`,
      choices: [
         {
            label: 'Timbriamo i biglietti',
            goTo: 'posto-sul-pullman',
            score: [score.character]
         },
         {
            label: "Risparmiamo il biglietto per un'altra occasione",
            goTo: 'posto-sul-pullman'
         }
      ]
   },
   {
      id: 'posto-sul-pullman',
      title: 'Sul pullman',
      text: `Una signora sulla cinquantina sale sul pullman alla fermata successiva. 
      I posti sono tutti occupati e siete combattuti se lasciarle il vostro posto oppure riposarvi per recuperare 
      le ore di sonno arretrato.`,
      choices: [
         {
            label: 'Lascio il posto alla signora',
            goTo: 'itinerario',
            score: [score.character]
         },
         {
            label: 'Mi riposo per recuperare le forze',
            goTo: 'itinerario',
            score: [score.strength]
         }
      ]
   },
   {
      id: 'itinerario',
      title: "L'itinerario",
      text: `Scesi dal pullman vi orientate con la cartina e capite che ci sono tre possibili vie. <br>
      Potete affrontare un percorso lungo ma panoramico e immerso nella natura; in alternativa un sentiero più corto passa 
      comunque nei boschi ma sembra meno suggestivo; infine la strada asfaltata vi porterebbe rapidamente all'arrivo.`,
      choices: [
         {
            label: 'Percorso lungo',
            goTo: 'slogatura'
         },
         {
            label: 'Percorso corto',
            goTo: 'torrente'
         },
         {
            label: 'Percorso cortissimo',
            goTo: 'mal-di-piedi'
         }
      ]
   },
   {
      id: 'slogatura',
      title: 'La slogatura',
      text: `Mentre camminate per un bellissimo sentiero di sassi e erba, uno squadriglino mette giù male il piede e 
      si sloga una caviglia. Anche se manca poco all'arrivo urge una soluzione.`,
      choices: [
         {
            label: "Facciamo l'autostop",
            goTo: 'rifiuti'
         },
         {
            label: 'Costruiamo una barella per trasportarlo',
            goTo: 'rifiuti',
            score: [score.skill],
            password: globalPassword
         },
         {
            label: "Lo prendiamo in braccio e lo portiamo fino all'arrivo",
            goTo: 'rifiuti',
            score: [score.strength],
            password: globalPassword
         }
      ]
   },
   {
      id: 'torrente',
      title: 'Il torrente',
      text: `Sulla strada incontrate un torrente che interrompe il tragitto.`,
      choices: [
         {
            label: 'Guadiamo a piedi nudi',
            goTo: 'rifiuti'
         },
         {
            label: 'Allunghiamo la strada',
            goTo: 'rifiuti'
         },
         {
            label: 'Costruiamo un passaggio con dei sassi',
            goTo: 'rifiuti',
            score: [score.skill],
            password: globalPassword
         }
      ]
   },
   {
      id: 'mal-di-piedi',
      title: 'Mal di piedi',
      text: `Nonostante la breve camminata l'asfalto causa dolori e vesciche al vice capo squadriglia.`,
      choices: [
         {
            label: "Sostiamo per un po'",
            goTo: 'rifiuti'
         },
         {
            label: 'Il vice resiste e continuiamo',
            goTo: 'rifiuti'
         }
      ]
   },
   {
      id: 'rifiuti',
      title: "L'immondizia",
      text: `Siete quasi arrivati a destinazione e vi accorgete che lungo il margine del sentiero 
      sono stati gettati diversi rifiuti.`,
      choices: [
         {
            label: 'Raccogliamo i rifiuti senza sapere quando potremo buttarli',
            goTo: 'rifugio',
            score: [score.care]
         },
         {
            label: 'Proseguiamo perché inizia a far buio',
            goTo: 'rifugio',
            score: []
         }
      ]
   },
   {
      id: 'rifugio',
      title: 'Il rifugio',
      text: `E' il momento di montare il rifugio. Trovate una posizione molto comoda vicino a degli alberi.`,
      choices: [
         {
            label: 'Appoggiamo il telo sui rami risparmiando 15 minuti',
            goTo: 'cena',
            score: []
         },
         {
            label: 'Fissiamo il telo con dei cordini',
            goTo: 'cena',
            score: [score.skill],
            password: globalPassword
         }
      ]
   },
   {
      id: 'cena',
      title: 'La cena',
      text: `Dopo tanta fatica, è finalmente arrivato il momento di cenare.`,
      choices: [
         {
            label: 'Recitiamo una preghiera',
            goTo: 'notte',
            score: [score.character]
         },
         {
            label: 'Mangiamo senza perdere tempo',
            goTo: 'notte',
            score: []
         }
      ]
   },
   {
      id: 'notte',
      title: 'La notte',
      text: `Uno squadriglino, coricato sul lato esterno del rifugio, inizia a sentire freddo.`,
      choices: [
         {
            label: 'Ciò che non uccide fortifica',
            goTo: 'mattino',
            score: []
         },
         {
            label:
               "Il capo squadriglia cede il posto al centro del rifugio rischiando l'influenza",
            goTo: 'mattino',
            score: [score.care]
         }
      ]
   },
   {
      id: 'mattino',
      title: 'Il mattino',
      text: `Vi svegliate dopo una lunga dormita ma sentite ma vi fanno male tutti i muscoli.`,
      choices: [
         {
            label: 'Facciamo ginnastica',
            goTo: 'attività',
            score: [score.strength],
            password: globalPassword
         },
         {
            label: 'Preghiamo',
            goTo: 'game-over',
            score: [score.character]
         }
      ]
   },
   {
      id: 'game-over',
      title: 'Game Over',
      text: `Avete perso... no scherzo`,
      choices: [
         {
            label: 'Continua il gioco',
            goTo: 'attività',
            score: []
         }
      ]
   },
   {
      id: 'attività',
      title: "L'attività in paese",
      text: `La lettera dei capi vi invita ad intervistare le persone del luogo.`,
      choices: [
         {
            label: 'Ci prepariamo e andiamo in paese',
            goTo: 'signora',
            score: []
         },
         {
            label: 'Relax sfrenato fino al pullman di ritorno',
            goTo: 'cane',
            score: []
         }
      ]
   },
   {
      id: 'cane',
      title: 'Il cane',
      text: `Mentre siete in panciolle arriva un cane randagio da dietro un cespuglio.`,
      choices: [
         {
            label: 'Gli diamo da mangiare il cioccolato',
            goTo: 'padrone',
            score: []
         },
         {
            label: 'Lo accarezziamo',
            goTo: 'padrone',
            score: []
         },
         {
            label: "Lo scacciamo con l'alpenstock",
            goTo: 'padrone',
            score: []
         }
      ]
   },
   {
      id: 'padrone',
      title: 'Il padrone',
      text: `Il cane si allontana e decidete di seguirlo portandovi dal padrone che vi offre un tè. 
      Gli chiedete:`,
      choices: [
         {
            label: 'Se può lasciarvi un pacco di biscotti',
            goTo: 'paolo-gorra',
            score: []
         },
         {
            label: 'Se ha bisogno di una mano per qualche lavoretto',
            goTo: 'paolo-gorra',
            score: [score.care]
         }
      ]
   },
   {
      id: 'signora',
      title: 'La signora',
      text: `Incontrate una vecchia signora del paese che chiede una mano per spostare la legna dal garage al salotto.`,
      choices: [
         {
            label: 'La aiutiamo',
            goTo: 'bambino',
            score: [score.care]
         },
         {
            label:
               'Le offriamo di trovare qualcuno che possa aiutarla per non perdere tempo',
            goTo: 'bambino',
            score: []
         }
      ]
   },
   {
      id: 'bambino',
      title: 'Il bambino',
      text: `Mentre fate l'attività un bambino vi interrompe e vi chiede se conoscete l'origine del nome 
      del paese "Ponte dell'olio". Rispondete che:`,
      choices: [
         {
            label: 'Era un paese celebre per i suoi uliveti',
            goTo: 'signore',
            score: []
         },
         {
            label:
               "Una leggenda narra di un carico d'olio rovesciato sul ponte",
            goTo: 'signore',
            score: []
         },
         {
            label:
               "In passato era un punto di passaggio importante per il commercio dell'olio",
            goTo: 'signore',
            score: [score.character]
         },
         {
            label: 'Il ponte originale era fatto di legno di ulivo',
            goTo: 'signore',
            score: []
         }
      ]
   },
   {
      id: 'signore',
      title: 'Il signore',
      text: `Un signore vi racconta di aver smarrito il suo cane e vi chiede se lo avete visto. 
      Vi sembra di aver visto passare un pastore tedesco ma il signore non sa di che razza sia.`,
      choices: [
         {
            label: 'Disegniamo un cane pastore',
            goTo: 'paolo-gorra',
            score: [score.skill],
            password: globalPassword
         },
         {
            label: 'Non siamo in grado di disegnare il cane',
            goTo: 'paolo-gorra',
            score: []
         }
      ]
   },
   {
      id: 'paolo-gorra',
      title: 'Il Paolo Gorra',
      text: `Alla fermata dell'autobus incontrate niente di meno che <strong>Paolo Gorra</strong> che vi chiede indicazioni per la 
      sede del PC1.`,
      choices: [
         {
            label: 'Disegniamo una cartina che parte dalla lupa',
            goTo: 'senza-tetto',
            score: [score.skill],
            password: globalPassword
         },
         {
            label: 'Non riusciamo a spiegarglielo',
            goTo: 'senza-tetto',
            score: []
         }
      ]
   },
   {
      id: 'senza-tetto',
      title: 'Il senza tetto',
      text: `Arrivati in stazione un senza tetto chiede la carità.`,
      choices: [
         {
            label:
               'Vi offrite di accompagnarlo da McDonald e fare merenda con lui',
            goTo: 'end',
            score: [score.care]
         },
         {
            label: 'Gli date dei soldi rinunciando alla merenda da McDonald',
            goTo: 'end',
            score: [score.strength]
         }
      ]
   },
   {
      id: 'end'
   }
];

export default pages;
